import template from './component.html';
import FormOverviewController from './controller';

export default {
  template: template,
  controller: FormOverviewController,
  controllerAs: 'vm',
  bindings: {
    formData: '<',
    formConfig: '<'
  }
};
