import template from './component.html';
import BookingsGuestsController from './controller';

export default {
  template: template,
  controller: BookingsGuestsController,
  controllerAs: 'vm',
  bindings: {
    guestsData: '<',
    guestFields: '<',
    onDataChange: '&',
    maxGuestCount: '<',
    minGuestCount: '<'
  }
};
