import env from '../../../env';

/**
 *
 */
export default class RegistrationController {
  constructor ($scope, AirLSTSdkService, $location, $q, $http, $sce) {
    this.scope = $scope;
    this.airlstService = AirLSTSdkService;
    this.locationService = $location;
    this.queue = $q;
    this.api = $http;
    this.sce = $sce;

    this.started = true;

    this.pageTitle = 'Event Registration';

    /* Setting parameters for overlay */
    let overlay;
    let dataPrivacy;
    let imprint;

    /* Setting parameters from env */
    this.airlstService.apiUrl = env.apiUrl;
    this.airlstService.companyUid = env.companyUid;

    this._resetState();
    this._startLoading();

    if (this._checkUrlPathInformation()) {
      this._retrieveStateDataFromApi();
    } else {
      this._stopLoading();
      this._setError('Error while loading informations for guestlist');
    }
  }

  openDataPrivacy () {
    this.overlay = true;
    this.dataPrivacy = true;
    window.scroll(0, 0);
  }

  openImprint() {
    this.overlay = true;
    this.imprint = true;
    window.scroll(0, 0);
  }

  closeOverlay() {
    this.overlay = false;
    this.dataPrivacy = false;
    this.imprint = false;
  }

  

  /**
   * Returns an env var or the default value given
   *
   * @param path
   * @param defaultReturn
   * @returns {*}
   */
  getEnvVar (path, defaultReturn = '') {
    return _.get(env, path, defaultReturn);
  }

  /**
   * Handles update of model data
   *
   * @param path
   * @param data
   */
  handleStateDataUpdate (path, data) {
    const state = this.state;

    _.set(state, (path ? path : ''), data);

    this._setState(state);

    this._updateWaitlistInformation();
  }

  /**
   *
   */
  startOpenRegistration () {
    if (this.state.eventInformation.password_protected) {
      this.api.post(this.getEnvVar('apiUrl') + '/guestlists/' + this.state.guestlistUid + '/check-password', {
        password: this.state.openPassword
      }, {
        header: {
          'x-airlst-company': this.getEnvVar('companyUid')
        }
      }).then(() => {
        this._setState({
          ...this.state,
          registrationType: 'open',
          responseType: 'submit'
        });

        this._updateWaitlistInformation();

        this._changeView('form');
      }, (e) => {
        this._setState({
          ...this.state,
          openPasswordInvalid: true,
          openPassword: ''
        });
      });
    } else {
      this._setState({
        ...this.state,
        registrationType: 'open',
        responseType: 'submit'
      });

      this._updateWaitlistInformation();

      this._changeView('form');
    }
  }

  /**
   *
   */
  startCodeRegistration () {
    this._loadRsvp().then(() => {

    }, (e) => {
      switch (e.identifier) {
        default:
          this._changeView('start');
          this._setCodeError('Der eingegebene Code ist ungültig.');
          break;
      }
    });
  }

  /**
   *
   */
  setResponseType (responseType) {
    this._setState({
      ...this.state,
      responseType: responseType
    });

    this._changeView('form');
  }

  /**
   *
   * @param form
   */
  submitData (form) {
    this._resetError();
    if (!form.$valid) {
      // window.scroll(0, 0);
      this._setError('Bitte überprüfe deine Daten');
      return;
    }

    this._setState({
      ...this.state,
      submitInProgress: true
    });

    let statusToSet = this._getStatusForCurrentBooking();

    const data = this._prepareModelDataForApi(statusToSet),
      promiseSuccessFunction = (response) => {
        this._setState({
          ...this.state,
          submitInProgress: false,
          modelData: {},
          guestsData: []
        });
        this._changeView('done');
        this._setSuccess('Ihre Daten wurden erfolgreich abgeschickt');
        window.scroll(0, 0);
      },
      promiseErrorCallback = (e) => {
        this._setState({
          ...this.state,
          submitInProgress: false
        });

        switch (e.identifier) {
          case 'validation':
            if(!_.isUndefined(e.errors.guests)) {
              this._setError('Bitte überprüfen Sie Ihre Gäste auf Vollständigkeit.');
              break;
            }
            if(!_.isUndefined(e.errors.recommendations)) {
              this._setError('Bitte überprüfen Sie Ihre Empfehlungen auf Vollständigkeit.');
              break;
            }
          default:
            this._setError('Fehler beim absenden der Daten. Bitte überprüfen Sie diese auf Richtigkeit.');
            break;
        }

        this._retrieveStateDataFromApi(true);
        // window.scroll(0, 0);
      };

    if (this.state.registrationType === 'code') {
      this.airlstService.updateRsvpByCode(this.state.rsvpCode, data).then(promiseSuccessFunction, promiseErrorCallback);
    } else {
      this.airlstService.submitOpenRequest(data).then(promiseSuccessFunction, promiseErrorCallback);
    }
  }

  /**
   *
   * @returns {string}
   * @private
   */
  _getStatusForCurrentBooking () {
    let out = 'confirmed';

    if (this.state.waitlistEnabled && this.state.bookingGoesToWaitlist) {
      out = 'waitlisted';
    } else if (this.state.registrationType !== 'code' && this._getEventInformationSetting('registration_status_flow').default.indexOf('confirmed') === -1) {
      out = 'requested';
    }

    if (this.state.responseType === 'cancel') {
      out = 'cancelled';
    }

    return out;
  }

  _getEventInformationSetting (path, defaultVal) {
    return _.get(this.state.eventInformation, `settings.${path}`, defaultVal || null);
  }

  /**
   *
   * @param status
   * @returns {*}
   * @private
   */
  _prepareModelDataForApi (status) {
    const data = _.cloneDeep(this.state.modelData);

    if (_.isUndefined(data.rsvp)) {
      data.rsvp = {};
    }

    data.rsvp.status = status;

    if (this.state.enableGuests && this.state.guestsData && !_.isEmpty(this.state.guestsData)) {
      data.guests = _.cloneDeep(this.state.guestsData);
      _.each(data.guests, (guest) => {
        if (_.isUndefined(guest.rsvp)) {
          guest.rsvp = {};
        }
        guest.rsvp.status = status;
      });
    }

    if (this.state.enableRecommendations && this.state.recommendationsData && !_.isEmpty(this.state.recommendationsData)) {
      data.recommendations = _.cloneDeep(this.state.recommendationsData);
      _.each(data.recommendations, (recommendation) => {
        if (_.isUndefined(recommendation.rsvp)) {
          recommendation.rsvp = {};
        }
        recommendation.rsvp.status = status;
      });
    }

    return data;
  }

  /**
   *
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   * @private
   */
  _loadRsvp () {
    this._resetCodeError();

    return this.airlstService.retrieveRsvpInformation(this.state.rsvpCode).then((bookingInformation) => {
      const availableResponseOptions = [],
        allowedStatesForState = _.get(this.state.eventInformation.settings.registration_status_flow, bookingInformation.rsvp.status);

      if (allowedStatesForState.indexOf('confirmed') !== -1) {
        availableResponseOptions.push('confirm');
      }

      if (allowedStatesForState.indexOf('cancelled') !== -1) {
        availableResponseOptions.push('cancel');
      }

      this._setState({
        ...this.state,
        registrationType: 'code',
        modelData: this._prepareModelDataFromApi(bookingInformation),
        guestsData: this._prepareGuestDataFromApi(bookingInformation),
        recommendationsData: this._prepareRecommendationsDataFromApi(bookingInformation),
        availableResponseOptions
      });

      this._updateWaitlistInformation();

      if (availableResponseOptions.length > 1) {
        this._changeView('response_type');
      } else {
        this._changeView('form');
      }
    }, (e) => {
      switch (e.identifier) {
        case 're_login_not_allowed':
          this._setCodeError('Dieser Code wurde bereits verwendet');
          break;
        case 'rsvp_not_found':
          this._setCodeError('Der Code existiert nicht');
          break;
        default:
          this._setCodeError('Es ist ein Fehler aufgetreten.');
          break;
      }
    });
  }

  /**
   *
   * @param data
   * @returns {*}
   * @private
   */
  _prepareModelDataFromApi (data) {
    return _.cloneDeep({
      rsvp: data.rsvp,
      contact: data.contact
    });
  }

  /**
   *
   * @param data
   * @returns {*}
   * @private
   */
  _prepareGuestDataFromApi (data) {
    return _.cloneDeep(data.guests);
  }

  /**
   *
   * @param data
   * @returns {*}
   * @private
   */
  _prepareRecommendationsDataFromApi (data) {
    return _.cloneDeep(data.recommendations);
  }

  /**
   * Fills the current state with the information for the event
   * @param eventInformation
   */
  _fillStateFromApiEventInformation (eventInformation) {
    this._setState({
      ...this.state,
      eventInformation: eventInformation,
      enableGuests: eventInformation.settings.enable_guests,
      enableRecommendations: eventInformation.settings.enable_recommendations,
      layout: {
        ...this.state.layout,
        headerImage: eventInformation.image_1 || this.state.layout.headerImage,
        headline: eventInformation.name,
        logo: eventInformation.image_2 || this.state.layout.logo,
        locationImage: eventInformation.image_3 || this.state.layout.locationImage,

        ansprechpartner: this.sce.trustAsHtml(eventInformation.custom_1 || this.state.layout.ansprechpartner),
        location: this.sce.trustAsHtml(eventInformation.custom_2 || this.state.layout.location),
        agenda: this.sce.trustAsHtml(eventInformation.custom_3 || this.state.layout.agenda),
        anfahrt: this.sce.trustAsHtml(eventInformation.custom_4 || this.state.layout.anfahrt),
        linkGoogleMaps: this.sce.trustAsHtml(eventInformation.custom_5 || this.state.layout.linkGoogleMaps),
        footerContactDetails: this.sce.trustAsHtml(eventInformation.custom_6 || this.state.layout.footerContactDetails),
        datenschutz: this.sce.trustAsHtml(eventInformation.custom_7 || this.state.layout.datenschutz),
        impressum: this.sce.trustAsHtml(eventInformation.custom_8 || this.state.layout.impressum),
        registrierungGeschlossen : this.sce.trustAsHtml(eventInformation.custom_9 || this.state.layout.registrierungGeschlossen),
        messageRequested : this.sce.trustAsHtml(eventInformation.custom_10 || this.state.layout.messageRequested),

        eventDescription: this.sce.trustAsHtml(eventInformation.settings.lp_text_1 || this.state.layout.eventDescription),
        paragraph2: this.sce.trustAsHtml(eventInformation.settings.lp_text_2 || this.state.layout.paragraph3),
        registrierung: this.sce.trustAsHtml(eventInformation.settings.lp_text_3 || this.state.layout.registrierung),
        textVorZusage: this.sce.trustAsHtml(eventInformation.settings.lp_text_4 || this.state.layout.textVorZusage),
        textVorAbsage: this.sce.trustAsHtml(eventInformation.settings.lp_text_5 || this.state.layout.textVorAbsage)
      }
    });
  }

  /**
   *
   * @private
   */
  _updateWaitlistInformation () {
    this._setState({
      ...this.state,
      waitlistEnabled: this._isWaitlistEnabled(),
      bookingGoesToWaitlist: this._isBookingBroughtToWaitlist()
    });

    /* Updating guest counts after setting waitlist Settings */
    this._setState({
      ...this.state,
      maxGuestCount: this._calculateMaxGuestCount(),
      minGuestCount: this._getEventInformationSetting('guest_count_minimum')
    });

    const newEventInformation = this.state.eventInformation;
    _.each(newEventInformation.fields, (group, key) => {
      if(!_.isUndefined(group.pax_planned)) {
        newEventInformation.fields[key].pax_planned.max = this._calculateMaxGuestCount()+1;
      }
    });


    this._setState({
      ...this.state,
      eventInformation: newEventInformation
    });
  }

  /**
   *
   * @returns {boolean}
   * @private
   */
  _isWaitlistEnabled () {
    return !!_.get(this.state.eventInformation, `pax_limit_information.${this.state.registrationType}.waitlist`);
  }

  /**
   *
   * @returns {boolean}
   * @private
   */
  _isBookingBroughtToWaitlist () {
    if (!this.state.waitlistEnabled) {
      return false;
    }

    const currentPaxLimitValue = _.get(this.state.eventInformation, `pax_limit_information.${this.state.registrationType}.free_slots`);

    return (currentPaxLimitValue !== -1 && currentPaxLimitValue < (1 + this.state.guestsData.length));
  }

  /**
   *
   * @returns {number}
   * @private
   */
  _calculateMaxGuestCount () {
    const currentPaxLimitValue = _.get(this.state.eventInformation, `pax_limit_information.${this.state.registrationType}.free_slots`),
      guestCountMaximum = this._getEventInformationSetting('enable_guests') ? this._getEventInformationSetting('guest_count_maximum') : (this._getEventInformationSetting('pax_planned_max') > -1) ? (this._getEventInformationSetting('pax_planned_max') - 1) : 0,
      rsvpPaxMax = _.get(this.state.modelData, 'rsvp.pax_max', 0);

    if(rsvpPaxMax === 1) {
      return -1;
    }
    if (!this.state.waitlistEnabled) {
      if (currentPaxLimitValue === -1 || currentPaxLimitValue >= (guestCountMaximum + 1)) {
        if (((guestCountMaximum + 1) <= rsvpPaxMax) || rsvpPaxMax === 0) {
          return guestCountMaximum;
        } else {
          return rsvpPaxMax - 1;
        }
      } else {
        if (currentPaxLimitValue <= rsvpPaxMax) {
          return currentPaxLimitValue - 1;
        } else {
          return rsvpPaxMax - 1;
        }

      }
    } else {
      if ((guestCountMaximum + 1) <= rsvpPaxMax) {
        return guestCountMaximum;
      } else {
        return rsvpPaxMax - 1;
      }
    }
  }

  /**
   *
   * @private
   */
  _changeView (newView) {
    this._resetError();
    this._resetSuccess();

    this._setState({
      ...this.state,
      currentView: newView
    });
  }

  /**
   * Retrieves event and rsvp information from api if necessary
   * @private
   */
  _retrieveStateDataFromApi (dontReloadRsvp) {
    this.airlstService.guestlistUid = this.state.guestlistUid;

    this.airlstService.retrieveGuestlistInformation().then((eventInformation) => {
      this._fillStateFromApiEventInformation(eventInformation);

      if (!dontReloadRsvp
          && this.state.rsvpCode
          && this.state.eventInformation.registration_started
          && !this.state.eventInformation.registration_closed) {
        this._loadRsvp().then(() => this._stopLoading);
      } else {
        this._stopLoading();
      }
    }, (exception) => this._handleAirlstErrors(exception));
  }

  /**
   * Handles errors generated by the AirLST API
   *
   * @param exception
   * @private
   */
  _handleAirlstErrors (exception) {
    console.log(exception);
  }

  /**
   * Checks the current location path for guestlist and booking identifiers
   * @returns {boolean}
   * @private
   */
  _checkUrlPathInformation () {
    let pathParts = _.filter(this.locationService.path().split('/'));

    if (pathParts.length === 0) {
      return false;
    }

    if (pathParts.length > 0) {
      this.state.guestlistUid = pathParts[0];
      if (pathParts.length > 1) {
        this.state.rsvpCode = pathParts[1];
      }
    }

    return true;
  }

  /**
   * sets loading state
   * @private
   */
  _startLoading () {
    this._setState({
      ...this.state,
      loading: true

    });
  }

  /**
   * stops loading state
   * @private
   */
  _stopLoading () {
    this._setState({
      ...this.state,
      loading: false
    });
  }

  /**
   * Sets the current success state
   *
   * @private
   */
  _setSuccess (message) {
    this._setState({
      ...this.state,
      success: {
        active: true,
        message: message
      }
    });
  }

  /**
   * Resets the current success state
   * @private
   */
  _resetSuccess () {
    this._setState({
      ...this.state,
      success: {
        active: false,
        message: ''
      }
    });
  }

  /**
   * Sets the current error state
   *
   * @private
   */
  _setError (message) {
    this._setState({
      ...this.state,
      error: {
        active: true,
        message: message
      }
    });
  }

  /**
   * Resets the current error state
   * @private
   */
  _resetError () {
    this._setState({
      ...this.state,
      error: {
        active: false,
        message: ''
      }
    });
  }

  /**
   * Sets the current code error state
   *
   * @private
   */
  _setCodeError (message) {
    this._setState({
      ...this.state,
      codeError: {
        active: true,
        message: message
      }
    });
  }

  /**
   * Resets the current code error state
   * @private
   */
  _resetCodeError () {
    this._setState({
      ...this.state,
      codeError: {
        active: false,
        message: ''
      }
    });
  }

  /**
   *
   * @private
   */
  _resetLayout () {
    this._setState({
      ...this.state,
      layout: {
        headerImage: this.getEnvVar('layout.default.headerImage', null),
        headline: this.getEnvVar('layout.default.headline', null),
        contentImage: this.getEnvVar('layout.default.contentImage', null),
        footerImage: this.getEnvVar('layout.default.footerImage', null),
        paragraph1: this.getEnvVar('layout.default.paragraph1', null),
        paragraph2: this.getEnvVar('layout.default.paragraph2', null),
        paragraph3: this.getEnvVar('layout.default.paragraph3', null),
        paragraph4: this.getEnvVar('layout.default.paragraph4', null),
        closingText: this.getEnvVar('layout.default.closingText', 'Die Anmeldung ist bereits geschlossen.')
      }
    });
  }

  /**
   * Updates the state scope save
   *
   * @param state
   * @private
   */
  _setState (state) {
    this.state = state;
    this.scope.$applyAsync();
  }

  /**
   * Resets the whole page state
   * @private
   */
  _resetState (keepLayout) {
    let oldLayout;

    if (!!keepLayout && this.state.layout) {
      oldLayout = _.cloneDeep(this.state.layout);
    }

    this.state = {
      guestlistUid: null,
      rsvpCode: null,
      loading: false,
      eventInformation: null,
      modelData: {},
      guestsData: [],
      recommendationsData: [],
      currentView: 'start',
      registrationType: '',
      responseType: 'confirm',
      openPasswordInvalid: false,
      codeInvalid: false,
      availableResponseOptions: [],
      submitInProgress: false,
      enableGuests: false,
      minGuestCount: 0,
      maxGuestCount: 0,
      waitlistEnabled: false,
      bookingGoesToWaitlist: false
    };

    this._resetError();
    this._resetSuccess();
    this._resetCodeError();
    this._resetLayout();

    if (!!keepLayout && oldLayout) {
      this.state.layout = oldLayout;
    }
  }
}

RegistrationController.$inject = [
  '$scope',
  'AirLSTSdkService',
  '$location',
  '$q',
  '$http',
  '$sce'
];
